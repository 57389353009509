import AMapLoader from '@amap/amap-jsapi-loader'
window._AMapSecurityConfig = {
  securityJsCode: '775d00f75fe0b7e774616c125c6f5f67'
}
export const mapKey = 'ad94d8d1c6ff75a7d037d9260868ab10'
export function initMap() {
  return AMapLoader.load({
    'key': mapKey, // 申请好的Web端开发者Key，首次调用 load 时必填
    'version': '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
    'plugins': ['AMap.Geocoder', 'AMap.Geolocation'] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
  })
}

/**
 * 打开高德或百度地图
 * @param {*} latitude
 * @param {*} longitude
 * @param {*} name  导航目的地名称
 * @param {*} type 1 百度地图 2 高德地图
 */
export const navToMap = (addressInfo, type) => {
  const { latitude, longitude, name, address } = addressInfo
  const mapUrlConfig = {
    baidu: 'baidumap://map/walknavi',
    gaode: {
      ios: 'iosamap://navi',
      android: 'androidamap://viewMap'
    },
    tengxun: 'qqmap://map/marker'
  }
  const addressStr = address || ''
  let url
  const u = navigator.userAgent
  // 判断Android
  const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1
  if (type === 1) {
    // 百度
    url = `${mapUrlConfig.baidu}?destination=${latitude},${longitude}&title=${name}&content=${addressStr}&output=html&coord_type=gcj02&src=test`
  } else {
    // 高德
    const params = `?sourceApplication=mingyue&poiname=${name}&lat=${latitude}&lon=${longitude}&dev=0&t=2`
    const { android, ios } = mapUrlConfig.gaode
    url = (isAndroid ? android : ios) + params
  }
  if (url) {
    window.location.href = url
  }
}
