<template>
  <div class="city-detail">
    <van-popup v-model="status" position="bottom" :close-on-click-overlay="false" @open="open" @click-overlay="goCancel">
      <div class="content">
        <div class="head">
          <div class="cancel" @click="goCancel">取消</div>
          <div class="title">请选择</div>
          <div class="confirm" @click="goConfirm">完成</div>
        </div>
        <div class="list">
          <div v-for="item in list" :key="item.areaCode" class="item" :class="activeCity.areaCode == item.areaCode ? 'active' : ''" @click="selectArea(item)">{{ item.areaName }}<img v-if="activeCity.areaCode == item.areaCode" src="@/assets/selected@2x.png" class="img"></div>
        </div>
        <div class="address">
          <div class="hd">
            <div class="tt">详细地址</div>
            <div class="ct" @click="goClear">清空</div>
          </div>
          <div class="bd">
            <van-field ref="address" v-model="activeCity.address" type="textarea" placeholder="请输入详细地址" maxlength="100" class="textarea" show-word-limit />
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getRegionByCityCode } from '@/api/product'
import { setLocalUserInfo, getLocalUserInfo } from '@/utils/auth'
import { initMap } from '@/utils/map'
export default {
  props: {
    infos: {
      type: Object,
      default: () => ({})
    },
    status: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      list: [],
      activeCity: {
        areaCode: '',
        address: '',
        center: ''
      },
      cityInfos: {},
      AMap: null
    }
  },
  mounted() {
    //
  },
  methods: {
    goClear() {
      this.activeCity.address = ''
    },
    open() {
      const { cityInfos } = getLocalUserInfo()
      this.cityInfos = cityInfos || {}
      if (cityInfos && cityInfos[this.infos.cityCode]) {
        this.activeCity = cityInfos[this.infos.cityCode]
      }
      this.getList()
    },
    goCancel() {
      this.activeCity = {
        areaCode: '',
        address: '',
        center: ''
      }
      this.$emit('update:status', false)
    },
    goConfirm() {
      this.$refs.address.blur()
      setTimeout(() => {
        if (!this.activeCity.areaCode && this.list.length > 0) return this.$toast('请选择区！')
        if (!this.activeCity.address) return this.$toast('请输入详细地址！')
        const cityInfos = this.cityInfos
        cityInfos[this.infos.cityCode] = this.activeCity
        const { cityCode, areaCode, areaName } = this.infos
        const center = this.activeCity.center ? this.activeCity.center.split(',') : this.infos.center.split(',')
        const location = { lng: center[0], lat: center[1] }
        const city = {
          cityCode,
          adCode: areaCode,
          areaName,
          longitude: location.lng,
          latitude: location.lat
        }
        setLocalUserInfo({ cityInfos, city })
        this.$router.go(-1)
      }, 400)
      // this.getAddressDetail()
    },
    async getAddressDetail() {
      if (!this.AMap) {
        this.AMap = await initMap()
      }
      this.AMap.plugin('AMap.Geocoder', () => {
        var geocoder = new this.AMap.Geocoder({
          // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
          city: this.activeCity.cityCode
        })

        geocoder.getLocation(this.activeCity.address, (status, result) => {
          if (status === 'complete' && result.info === 'OK') {
            console.log(result, '111')
            // result中对应详细地理坐标信息
            const { cityCode, areaCode, areaName } = this.infos
            const center = this.activeCity.center.split(',')
            const location = result.geocodes[0] && result.geocodes[0].location || { lng: center[0], lat: center[1] }
            setLocalUserInfo({
              city: {
                cityCode,
                adCode: areaCode,
                areaName,
                longitude: location.lng,
                latitude: location.lat
              }
            })
            this.$router.go(-1)
          }
        })
      })
    },
    selectArea(item) {
      console.log(item)
      this.activeCity.cityCode = item.cityCode
      this.activeCity.areaCode = item.areaCode
      this.activeCity.center = item.center
      console.log(this.activeCity)
    },
    async getList() {
      const res = await getRegionByCityCode({ cityCode: this.infos.cityCode })
      this.list = res.datas && res.datas.listOpenCity || []
      // const cityInfos = this.data.cityInfos[cityCode] || {}
      // this.setData({
      //   areaList: res.datas && res.datas.listOpenCity || [],
      //   address: cityInfos.address || '',
      //   areaCode: cityInfos.areaCode || '',
      //   locationTmp: cityInfos.center
      // })
    }
  }
}
</script>

<style lang="scss" scoped>
.city-detail::v-deep {
  .van-popup {
    border-radius: 16px 16px 0px 0px;
  }
  .content {
    width: 100%;
    padding: 0 16px;
    box-sizing: border-box;
    z-index: 9999;
    border-radius: 16px 16px 0px 0px;
    background: #fff;
    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 0;
      font-size: 14px;
      .cancel {
        color: #999999;
      }
      .confirm {
        color: #FF7700;
      }
      .title {
        font-size: 16px;
        color: #2E3135;
      }
    }
    .list {
      max-height: 165px;
      overflow: auto;
      .item {
        display: flex;
        justify-content: space-between;
        padding: 11px 0;
        font-size: 14px;
        color: #2E3135;
        border-bottom: 1px solid #F3F3F5;
        &.active {
          color: #FF7700;
        }
        .img {
          width: 17px;
          height: 12px;
        }
      }
    }
    .address {
      .hd {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;
        font-size: 12px;
        .tt {
          color: #999999;
        }
        .ct {
          color: #1478FF;
        }
      }
      .bd {
        padding-bottom: 20px;
        .textarea {
          width: 100%;
          padding: 10px;
          box-sizing: border-box;
          border-radius: 4px;
          border: 0;
          background: #FAFAFA;
        }
      }
    }
  }
}
</style>
