<template>
  <div class="city-list">
    <div class="search">
      <van-field v-model="keyWord" label-width="auto" placeholder="请输入城市名" @input="getList">
        <div slot="label" class="left">
          <img src="@/assets/search@2x.png" class="icon">
        </div>
      </van-field>
    </div>
    <div class="location">
      <div class="title">当前定位城市</div>
      <div class="cont" @click="selectLocation">
        <img src="@/assets/location/locate@2x.png" class="icon">
        {{ location.areaName }}
        <span v-if="location.areaName == '定位失败'" class="reset" @click.stop="goLocation">重新定位</span>
      </div>
    </div>
    <div class="city">
      <div v-if="hotList.length > 0" class="hot">
        <div class="title">热门城市</div>
        <div class="list">
          <div v-for="item in hotList" :key="item.cityCode" class="item" @click="goSelect(item)">{{ item.areaName }}</div>
        </div>
      </div>
      <van-index-bar ref="indexBar" :sticky="false" :index-list="indexList" @change="indexChange">
        <template v-for="item in list">
          <van-index-anchor :key="item.key" :index="item.key" />
          <van-cell v-for="it in item.listRegion" :key="it.cityCode" :title="it.areaName" @click="goSelect(it)" />
        </template>
      </van-index-bar>
    </div>
    <CityDetail :status.sync="detailStatus" :infos="details" />
    <div class="index-list">
      <div v-for="item in indexList" :key="item" class="item" :class="{ active: item == index && !indexLoading }" @click="scrollTo(item)">{{ item }}</div>
    </div>
  </div>
</template>

<script>
import { selectAllCity } from '@/api/product'
import { checkCity } from '@/api/user'
import CityDetail from './components/CityDetail'
import { initMap } from '@/utils/map'
import { setLocalUserInfo } from '@/utils/auth'
export default {
  components: { CityDetail },
  data() {
    return {
      index: 'A',
      indexLoading: false,
      indexList: [],
      list: [],
      hotList: [],
      keyWord: '',
      detailStatus: false,
      details: {},
      location: {
        adCode: '',
        areaName: '定位中',
        cityCode: '',
        latitude: '',
        longitude: ''
      }, // 定位信息
      AMap: null
    }
  },
  mounted() {
    this.goLocation()
    this.getList()
  },
  methods: {
    indexChange(index) {
      console.log(index, '9')
      this.index = index
    },
    scrollTo(index) {
      this.indexLoading = true
      setTimeout(() => {
        this.index = index
        this.indexLoading = false
      }, 200)
      this.$refs.indexBar.scrollTo(index)
    },
    async goSelect(item) {
      const res = await checkCity({ cityCode: item.cityCode })
      if (res.datas !== 1) return
      this.details = item
      this.detailStatus = true
    },
    async selectLocation() {
      if (!this.location.cityCode) return
      const res = await checkCity({ cityCode: this.location.cityCode })
      if (res.datas !== 1) return
      setLocalUserInfo({ city: this.location })
      this.$router.go(-1)
    },
    async goLocation() {
      this.location.areaName = '定位中'
      this.AMap = await initMap()
      this.AMap.plugin('AMap.Geolocation', () => {
        var geolocation = new this.AMap.Geolocation({
          // 是否使用高精度定位，默认：true
          enableHighAccuracy: true,
          // 设置定位超时时间，默认：无穷大
          timeout: 10000,
          showMarker: false, // 定位成功后在定位到的位置显示点标记，默认：true
          showCircle: false // 定位成功后用圆圈表示定位精度范围，默认：true
        })
        geolocation.getCurrentPosition(async(status, result) => {
          console.log(status, result, '-----')
          if (status === 'complete') {
            // data是具体的定位信息
            const { lat, lng } = result.position
            this.location.latitude = lat
            this.location.longitude = lng
            this.AMap.plugin('AMap.Geocoder', () => {
              var geocoder = new this.AMap.Geocoder()
              var lnglat = [lng, lat]
              geocoder.getAddress(lnglat, async(status, result) => {
                if (status === 'complete' && result.info === 'OK') {
                  const city = result.regeocode.addressComponent
                  // result为对应的地理位置详细信息
                  this.location.cityCode = city.citycode
                  this.location.areaName = city.city
                  this.location.adCode = city.adcode
                }
              })
            })
          } else {
            //
            this.location.areaName = '定位失败'
          }
          // 失败或者成功都建立中心点
        })
      })
    },
    async getList() {
      const res = await selectAllCity({ keyWord: this.keyWord })
      this.list = res.datas && res.datas.listByPinYin || []
      this.hotList = res.datas && res.datas.listHotCity || []
      this.indexList = res.datas.listByPinYin.map(item => {
        return item.key
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.city-list {
  display: flex;
  flex-flow: column;
  height: 100%;
  background: #fcfcfc;
  .search::v-deep {
    border-bottom: 1px solid #e5e5e5;
    .van-field__label {
      display: flex;
      align-items: center;
      .left {
        display: flex;
        .icon {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
  .location {
    padding: 16px;
    .title {
      font-size: 12px;
      color: #999999;
    }
    .cont {
      display: flex;
      align-items: center;
      padding-top: 20px;
      .icon {
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }
      .reset {
        font-size: 12px;
        color: #FF7226;
        padding-left: 10px;
      }
    }
  }
  .city {
    flex: 1;
    overflow-y: auto;
    .hot {
      padding: 10px 16px 16px 16px;
      .title {
        font-size: 12px;
        color: #999999;
      }
      .list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 15px 0;
        .item {
          padding: 8px 12px;
          margin-right: 10px;
          margin-bottom: 10px;
          border: 1px solid #CCCCCC;
          border-radius: 4px;
          font-size: 14px;
          color: #666666;
        }
      }
    }
  }
  .index-list {
    position: fixed;
    height: 100%;
    top: 0;
    right: 5px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    .item {
      padding: 2px 0;
      &.active {
        color: #FF7226;
      }
    }
  }
}
.city-list::v-deep {
  .van-index-bar__sidebar {
    display: none;
  }
}

</style>
